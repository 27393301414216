import type { ReactNode } from 'react'
import { useEffect, useMemo } from 'react'

import type {
  LDContext,
  LDReactOptions,
  LDFlagSet,
  LDOptions,
} from 'launchdarkly-react-client-sdk'
import {
  LDProvider,
  useFlags,
  useLDClient,
} from 'launchdarkly-react-client-sdk'

import { getLDContext } from '@b-stock/bstock-next'

import useAccountId from '@helpers/useAccountId'

type TabComponentEnabled =
  | { aboutEnabledStorefrontIds: string[]; faqEnabledStorefrontIds: string[] }
  | false

export class FeatureFlag<T> {
  readonly key: string
  readonly defaultValue: T
  constructor(key: string, defaultValue: T) {
    this.key = key
    this.defaultValue = defaultValue
  }
}

export const FeatureFlags = {
  ENABLE_BUY_NOW: new FeatureFlag('enable-buy-it-now', false),
  ENABLE_ABOUT_FAQ_BY_STOREFRONT_ID: new FeatureFlag<
    TabComponentEnabled | false
  >('enable-about-faq-by-storefront-id', {
    aboutEnabledStorefrontIds: [],
    faqEnabledStorefrontIds: [],
  }),
} as const satisfies Partial<Record<string, FeatureFlag<unknown>>>

const reactOptions: LDReactOptions = {
  useCamelCaseFlagKeys: false,
}

const loggerOptions: LDOptions = {
  logger: {
    debug(message) {
      console.debug('[LaunchDarkly]', message)
    },
    info(message) {
      console.info('[LaunchDarkly]', message)
    },
    warn(message) {
      console.warn('[LaunchDarkly]', message)
    },
    error(message) {
      console.error('[LaunchDarkly]', message)
    },
  },
}

export const FeatureFlagProvider = ({
  children,
  featureFlags,
  clientSideId,
}: {
  children: ReactNode
  featureFlags: LDFlagSet
  clientSideId: string | null
}) => {
  const accountId = useAccountId()
  const context = useMemo<LDContext>(
    () => getLDContext({ accountId: accountId || undefined }),
    [accountId]
  )

  const options = useMemo(() => {
    return { ...loggerOptions, bootstrap: featureFlags }
  }, [featureFlags])

  useEffect(() => {
    if (!clientSideId) {
      console.warn(
        'LaunchDarkly clientSideId not specified - there should be server-side logs with additional details'
      )
    }
  }, [clientSideId])

  return (
    <LDProvider
      clientSideID={clientSideId || ''}
      context={context}
      reactOptions={reactOptions}
      options={options}
    >
      {children}
    </LDProvider>
  )
}

const getFlag = <T,>(flags: LDFlagSet | undefined, flag: FeatureFlag<T>): T => {
  return (flags?.[flag.key] as T | undefined) ?? flag.defaultValue
}

export const useFlag = <T,>(flag: FeatureFlag<T>): T => {
  const flags = useFlags()
  return getFlag(flags, flag)
}

export const useFeatureFlagClient = () => {
  const ldClient = useLDClient()
  return useMemo(() => {
    return {
      getFlag: <T,>(flag: FeatureFlag<T>): T => {
        return getFlag(ldClient?.allFlags(), flag)
      },
      ldClient,
    }
  }, [ldClient])
}
